<template>
  <div class="box">
    <div class="headline">
      基础资料
    </div>
    <div class="content">
        <p>设置公司架构，人员资料以及所在公司的部门岗位以及职能，基础资料是检查与学习的基础。</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
